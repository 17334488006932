import { axiosInstance } from "services";
import toastr from "toastr";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import platform from "platform";
import axios from "axios";
Cookies.set("device_id", uuidv4(), { expires: 9999 });
export const AmptitudeEvents = async (
  pageName,
  email,
  event,
  referer_page,
  companyId,
  companyName,
  keyword,
  page_name,
  page_title
) => {
  try {
    let amptitudeDate = new Date();

    const time = amptitudeDate.getMilliseconds();
    const isImpersonated = localStorage.getItem("impersonation");
    // const os_name = navigator.userAgentData.platform;
    if (!isImpersonated) {
      // const locationResponse = await axios.get(
      //   "https://geolocation-db.com/json/"
      // );
      if (true) {
        const response = await axiosInstance.post(`/log-manager/save`, {
          destination: ["bigquery", "amplitude"],
          data: {
            // os_name: os_name,
            os_version: platform?.os?.version,
            device_id: Cookies.get("device_id"),
            event_id: null,
            user_id: email ? email : null,
            event: event,
            event_properties: {
              page_name: pageName,
              page_title: pageName,
              keyword: keyword,
            },
            groups: {
              company_id: companyId,
              company_name: companyName,
            },
            referer: referer_page ? referer_page : window.location.href,
            app_version: platform.version,
            platform: "WEB",
            // location_lat: locationResponse?.data?.latitude.toString(),
            // location_lng: locationResponse?.data?.longitude.toString(),
            // country: locationResponse?.data?.country_name,
            // region: locationResponse?.data?.state,
            // city: locationResponse?.data?.city,
            // ip: locationResponse?.data?.IPv4,
            language: navigator.language,
            screen_size: `${screen.width}/${screen.height}`,
          },
          // events: [
          //   {
          //     user_id: email ? email : null,
          //     device_id: Cookies.get("device_id"),
          //     event_type: event,
          //     event_properties: {
          //       keyword: keyword,
          //     },
          //     user_properties: {
          //       screen_size: `${screen.width}/${screen.height}`,
          //       referer_page: referer_page
          //         ? referer_page
          //         : window.location.href,
          //     },
          //     groups: {
          //       company_id: companyId,
          //       company_name: companyName,
          //     },
          //     app_version: platform.version,
          //     platform: "WEB",
          //     os_name: os_name,
          //     os_version: platform?.os?.version,
          //     carrier: null,
          //     location_lat: locationResponse?.data?.latitude,
          //     location_lng: locationResponse?.data?.longitude,
          //     country: locationResponse?.data?.country_name,
          //     region: locationResponse?.data?.state,
          //     city: locationResponse?.data?.city,
          //     ip: locationResponse?.data?.IPv4,
          //     language: navigator.language,
          //     event_id: null,
          //     session_id: 1396381378123,
          //   },
          // ],
        });
        if (response) {
          return response.data;
        }
      }
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
  }
};
