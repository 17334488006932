import { axiosInstance } from "services";
import toastr from "toastr";
import { IMPERSONATION } from "../constants/localStorageKeys";

export const verifyImpersonation = async (signature) => {
    try {
        const response = await axiosInstance.post("/impersonation/verify", {
            signature,
        });

        if (response?.status === 204 || response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        console.error(error);
        toastr.error(error.message || error.errors[0]?.message);
    }
};

export const clearImpersonation = () => {
    const impersonation = localStorage.getItem(IMPERSONATION);

    if (!impersonation) return;
    localStorage.removeItem(IMPERSONATION);
    localStorage.removeItem("impersonationAdminEmail")
};
