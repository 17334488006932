export const APP_OPEN = "app_open";
export const LOGIN_CLICKED = "login_clicked";
export const CREATE_YOUR_ACCOUNT_CLICKED = "create_your_account_clicked";
export const USER_DETAILS_ENTERED = "user_details_entered";
export const EMAIL_VERIFICATION_SELECTED = "email_verification_selected";
export const SMS_VERIFICATION_SELECTED = "sms_verification_selected";
export const SEND_VERIFICATION_EMAIL_CLICKED =
  "send_verification_email_clicked";
export const RESEND_VERIFICATON_EMAIL_BUTTON_CLICKED =
  "resend_verification_email_button_clicked";
export const VERIFICATION_COMPLETED = "verification_completed";
export const SMS_CODE_ENTERED_EVENT = "sms_code_entered_event";
export const CREATE_COMPANY_PROFILE_BUTTON_CLICKED =
  "create_company_profile_button_clicked";

export const JOIN_COMPANY_BUTTON_CLICKED = "join_company_button_clicked";
export const COMPANY_CREATED = "company_created";
export const MULTI_SEARCH = "multi_search";
export const MULTI_SEARCH_OFFERS_SEE_ALL_CLICKED =
  "multi_search_offers_see_all_clicked";

export const MULTI_SEARCH_DEMANDS_SEE_ALL_CLICKED =
  "multi_search_demands_see_all_clicked";

export const MULTI_SEARCH_COMPANY_SEE_ALL_CLICKED =
  "multi_search_company_see_all_clicked";

export const MULTI_SEARCH_CONNECTIONS_SEE_ALL_CLICKED =
  "multi_search_connections_see_all_clicked";

export const MULTI_SEARCH_OFFERS_CLICKED = "multi_search_offers_clicked";
export const MULTI_SEARCH_DEMANDS_CLICKED = "multi_search_demands_clicked";
export const MULTI_SEARCH_COMPANY_CLICKED = "multi_search_company_clicked";
export const MULTI_SEARCH_USERS_CLICKED = "multi_search_users_clicked";
export const SOCIAL_FEED_TEXT_POST = "social_feed_text_post";
export const SOCIAL_FEED_VIDEO_POST = "social_feed_video_post";
export const SOCIAL_FEED_FILE_POST = "social_feed_file_post";
export const SOCIAL_FEED_IMAGE_POST = "social_feed_image_post";
export const SOCIAL_FEED_OFFER_POST = "social_feed_offer_post";
export const SOCIAL_FEED_DEMAND_POST = "social_feed_demand_post";
export const USER_INAPP_MESSAGE_SENT = "user_inapp_message_sent";
export const WRONG_PASSWORD_ENTERED = "wrong_password_entered";
export const PAGE_VIEW = "page_view";
